<template lang="pug">
    .text-center
        img.img-fluid(alt='Vue logo' src='../assets/logo.png')
</template>

<script>
export default {
  name: "octopus"
};
</script>
