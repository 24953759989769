<template lang="pug">
  .home
    Octopus
    .row
      .col
        h1
          | H.I.C. Octopus
          small.text-muted  is een vriendengroep.

        p.
          Sedert 1954 heerscht #[i Het Illustere College] op Societeit Tyche; de thuishaven van de Delftsche Studenten Bond.
        p.
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit; Aenean commodo ligula eget dolor!
          Aenean massa! Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus?
          Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem! Nulla consequat massa quis enim!
          Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu? In enim justo, rhoncus ut,
          imperdiet a, venenatis vitae, justo;
</template>

<script>
import Octopus from "@/components/Octopus.vue";

export default {
  name: "home",
  components: {
    Octopus
  }
};
</script>
